/* informational header */
.iconLink {
  color: white;
  font-size: 18px;
  transition: transform 0.4s ease, color 0.3s ease;
  margin-right: 22px;
  text-decoration: none;
}

.iconLink:hover {
  /* color: #ffcccb; Lighter shade of red */
  color: #ffd700;
  text-shadow: 1px 2px 9px #ffffff;
  transform: scale(1.3); /* Slightly enlarge on hover */
  text-decoration: none; /* Remove underline */
}

/* informationan header need to verify */

.informationHeader {
  background-color: #800000; /* Background color */
  color: white;
  padding: 2px 0; /* Decrease top and bottom padding to reduce height */
}

.informationHeader .contact-details {
  font-size: 12px; /* Further reduce font size for contact details */
}

.informationHeader .social-icons a {
  font-size: 16px; /* Smaller icon size */
  margin: 0 8px; /* Reduce margin between icons */
}

.informationHeader .social-icons a:hover {
  color: #ffd700; /* Optional: color change on hover */
}

/* main header */

/* logo css */
.logo {
  transition: transform 0.7s ease-in-out; /* Smooth transition for transformation */
  transform-style: preserve-3d;
  width: 50px; /* Set the desired width */
  height: 50px; /* Set the desired height */
  object-fit: contain; /* Ensure logo doesn't stretch */
  scale: 1.5;
}

/* ---------- */

/* ------------ */

.logo:hover {
  transform: rotateY(360deg);
  /* scale(1.5); Flip around the Y-axis */
}

.nav-hover {
  font-size: 1.3rem;
  color: white !important;
  transition: all 0.3s ease-in-out;
  margin-right: 17px; /* Add spacing between links */
  font-family: "Arial, sans-serif";
}

.nav-hover:hover {
  color: #ffd700 !important;
  text-shadow: 0px 0px 5px #ffffff;
  transform: scale(1.1);
}

/* Navbar brand hover effect */
.brand-hover {
  font-size: 1.5rem;
  font-weight: bold;
  color: white !important;
  transition: all 0.5s ease-in-out;
}

.brand-hover:hover {
  color: gold !important;
  text-shadow: 0px 0px 5px #ffffff;
}

/* Navbar responsive toggle button styling */
.navbar-toggler {
  border-color: whitesmoke;
}

.navbar-toggler-icon {
  background-color: white;
  border-radius: 3px;
}

/* Improve spacing for menu items */
.navbar-nav .nav-link {
  margin-right: 20px;
}

/* Make the navbar sticky on top */
.navbar {
  position: sticky;
  top: 0;
  z-index: 1000;
}

/* ------------get Quote CSS ------------------ / */
/* Modal Styles */
.quoteForm {
  padding: 10px;
}

.quoteForm .inputField {
  border-radius: 30px;
  border: 1px solid #ccc;
  padding: 10px 15px;
  font-size: 14px;
  width: 100%;
}

.quoteForm .inputField:focus {
  border-color: teal;
  box-shadow: 0 0 5px rgba(0, 128, 128, 0.5);
  outline: none;
}

.quoteForm .submitButton {
  font-size: 16px;
  padding: 10px;
  transition: all 0.3s ease;
}

.quoteForm .submitButton:hover {
  background-color: #005f5f;
  color: #fff;
}

/* Success Message */
.text-center.text-success h5 {
  color: #28a745;
}

.text-center.text-success p {
  margin: 0;
}

.phoneNumber {
  font-size: 14px; /* Smaller font size */
}

@media (max-width: 768px) {
  .phoneNumber {
    font-size: 12px; /* Further reduce font size for smaller screens */
  }
}
