* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
}
/* --- Header ---  */

.list-group-item-action {
  cursor: pointer;
}

.list-group-item-action:hover {
  background-color: #f8f9fa;
  /* color: #ff0000; */
}

@media (max-width: 767.98px) {
  .form-control {
    width: 100%;
  }

  .list-group {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .form-control {
    width: auto;
  }

  .list-group {
    width: 100%;
  }
}

/**  contact us css ---------------------  */
/* General Styles */
body {
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
  color: #333;
}

/* Contact Main Section */
.contact-main {
  max-width: auto;
  margin: 0 auto;
}


/* Hero Section */
.contact-hero {
  background-image: url("./assets/images/bg-img-122.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  text-align: center;
  padding: 100px 20px;
  position: relative;
  z-index: 1;
  width: 100%; /* Ensure full width */
  margin: 0; /* Remove any margins */
  overflow: hidden; /* Prevent overflow */
}

.contact-hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.contact-hero h1 {
  font-size: 3rem;
  margin-bottom: 10px;
}

.contact-hero p {
  font-size: 1.2rem;
  line-height: 1.6;
  max-width: 600px;
  margin: 0 auto;
}
/* Contact Information Section */
.contact-info-container {
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
  flex-wrap: wrap;
  margin: 60px auto;
}

.contact-info-box {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* width: calc(50% - 20px); Ensures the boxes take up 50% width minus the gap */
  flex: 1 1 400px;
  min-width: 300px;
  max-width: 550px;
  padding: 30px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-top: -90px;
  border: 1px solid #f0f0f0;
  z-index: 1;
}

.contact-info-box:hover {
  transform: translateY(-8px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.contact-info-box h2 {
  font-size: 1.8rem;
  color: #800000;
  margin-bottom: 15px;
}

.contact-info-box p {
  font-size: 1.1rem;
  color: #333;
  margin: 10px 0;
}

.contact-info-box a {
  color: #800000;
  text-decoration: none;
}

.contact-info-box a:hover {
  text-decoration: underline;
}

.contact-icon {
  font-size: 3rem;
  color: #800000;
  margin-bottom: 15px;
  transition: transform 0.3s ease;
}

.contact-icon:hover {
  transform: scale(1.1);
}

/* Contact Form */
.contact-form-container {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 650px;
  margin: 60px auto;
}

.contact-form-container h2 {
  font-size: 2rem;
  color: #800000;
  margin-bottom: 30px;
}

.contact-form-container form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-form-container label {
  font-size: 1.1rem;
  color: #555;
  text-align: left;
}

.contact-form-container input,
.contact-form-container textarea {
  width: 100%;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1.1rem;
  outline: none;
  transition: border-color 0.3s ease;
}

.contact-form-container input:focus,
.contact-form-container textarea:focus {
  border-color: #800000;
}

.contact-form-container button {
  background-color: #0073e6;
  color: #ffffff;
  padding: 15px 25px;
  border: none;
  border-radius: 8px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-top: 20px;
}

.contact-form-container button:hover {
  background-color: #800000;
  transform: translateY(-3px);
}

.contact-form-container button:active {
  transform: translateY(1px);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .contact-info-container {
    flex-direction: column;
    margin: 40px auto;
  }

  .contact-info-box {
    width: 100%;
    margin-top: -45px;
    margin-bottom: 30px;
  }

  .contact-form-container {
    width: 100%;
  }

  .contact-hero h1 {
    font-size: 2.5rem;
  }

  .contact-hero p {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .contact-hero {
    padding: 60px 20px;
  }

  .contact-hero h1 {
    font-size: 2rem;
  }

  .contact-hero p {
    font-size: 1rem;
  }

  .contact-form-container {
    padding: 25px;
  }

  .contact-form-container h2 {
    font-size: 1.6rem;
  }

  .contact-form-container input,
  .contact-form-container textarea {
    padding: 12px;
  }

  .contact-form-container button {
    padding: 12px 20px;
  }

  .contact-icon {
    font-size: 2.5rem;
  }
}

@media (max-width: 480px) {
  .contact-hero {
    padding: 40px 20px;
  }

  .contact-hero h1 {
    font-size: 1.8rem;
  }

  .contact-hero p {
    font-size: 1rem;
  }

  .contact-form-container h2 {
    font-size: 1.4rem;
  }

  .contact-form-container input,
  .contact-form-container textarea {
    padding: 10px;
  }

  .contact-form-container button {
    padding: 10px 18px;
  }

  .contact-icon {
    font-size: 2rem;
  }
}


/* Footer Styles */
.contact-footer {
  background-color: #2c3e50;
  color: #ecf0f1;
  padding: 40px 20px;
  text-align: left;
}

.contact-footer-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
  gap: 40px;
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
}

.contact-footer-left {
  flex: 1;
  min-width: 300px; /* Ensure a minimum width for columns */
}

.contact-footer-left h2 {
  font-size: 2rem;
  color: #e74c3c;
  margin-bottom: 10px;
}

.contact-footer-left p {
  font-size: 1rem;
  margin-bottom: 20px;
}

.contact-footer-right {
  flex: 2;
  display: flex;
  justify-content: space-between;
  gap: 40px;
  flex-wrap: wrap; /* Adjust layout on smaller screens */
}

.contact-footer-contact,
.contact-footer-support {
  flex: 1;
  min-width: 250px; /* Minimum width to prevent overly narrow columns */
}

.contact-footer-contact h3,
.contact-footer-support h3 {
  font-size: 1.4rem;
  color: #e74c3c;
  margin-bottom: 10px;
}

.contact-footer-contact ul,
.contact-footer-support ul {
  list-style: none;
  padding: 0;
}

.contact-footer-contact li,
.contact-footer-support li {
  font-size: 1.1rem;
  margin-bottom: 8px;
}

.contact-footer-contact a,
.contact-footer-support a {
  color: #ecf0f1;
  text-decoration: none;
  transition: color 0.3s ease;
}
.footer-main-section-links a{
  text-decoration: none !important;
  color: white;
}

.contact-footer-contact a:hover,
.contact-footer-support a:hover {
  color: #e74c3c;
}

.contact-footer-bottom {
  margin-top: 20px;
  text-align: center;
  font-size: 1rem;
  color: #bdc3c7;
}

.contact-footer-bottom p {
  margin: 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .contact-footer-container {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    text-align: center;
  }

  .contact-footer-left {
    margin-bottom: 20px;
    text-align: center; /* Center align for better readability */
  }

  .contact-footer-right {
    flex-direction: column;
    gap: 20px; /* Add spacing between sections */
    align-items: center;
  }

  .contact-footer-contact,
  .contact-footer-support {
    min-width: unset; /* Remove minimum width constraint */
    width: 100%; /* Expand to full width */
  }
}

@media (max-width: 480px) {
  .contact-footer-left h2 {
    font-size: 1.8rem; /* Adjust font size for smaller screens */
  }

  .contact-footer-contact h3,
  .contact-footer-support h3 {
    font-size: 1.2rem; /* Reduce heading size */
  }

  .contact-footer-contact li,
  .contact-footer-support li {
    font-size: 1rem; /* Reduce font size */
  }

  .contact-footer {
    padding: 20px 10px; /* Reduce padding for smaller screens */
  }

  .contact-footer-container {
    gap: 10px; /* Reduce gaps for smaller devices */
  }
}

/* Modal Backdrop */
.modal-contact-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Container */
.modal-contact {
  background: white;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
  padding: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  position: relative;
  text-align: center;
}

/* Modal Backdrop */
.modal-contact-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Container */
.modal-contact {
  background: linear-gradient(to bottom right, #ffcccc, #ff6666, #800000);
  border-radius: 15px;
  width: 400px;
  max-width: 90%;
  padding: 25px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.5);
  text-align: center;
  color: #fff;
}

/* Modal Header */
.modal-contact h2 {
  margin-top: 0;
  font-size: 2rem;
  color: #fff;
  text-shadow: 1px 1px 2px #800000;
}

/* Modal Text */
.modal-contact p {
  margin: 10px 0;
  font-size: 1rem;
  color: #f9f9f9;
}

/* Smooth Transition for Appearance */
.modal-contact {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}



/* -------------get Quote button css ------------------ */
/* Modal Styles */
.modal-content {
  border-radius: 15px;
  border: none;
  background: linear-gradient(to bottom right, #fff5f5, #ffcccc);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  padding: 20px;
}

/* Modal Header */
.modal-header {
  border-bottom: none;
  text-align: center;
  font-family: "Arial", sans-serif;
}

.modal-title {
  font-size: 24px;
  font-weight: bold;
  color: #800000;
  align-items: center;
  justify-content: center;
}

/* Modal Body */
.modal-body {
  font-family: "Roboto", sans-serif;
  color: #333;
  padding: 20px;
}

/* Form Input Fields */
form .form-control {
  border: 2px solid #800000;
  border-radius: 8px;
  padding: 10px;
  font-size: 16px;
  color: #555;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

form .form-control:focus {
  border-color: #ff6666;
  box-shadow: 0 0 6px rgba(255, 102, 102, 0.8);
}

/* Form Labels */
form .form-label {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 5px;
  color: #800000;
}

/* Submit Button */
button[type="submit"] {
  background-color: #800000;
  color: white;
  border: none;
  font-weight: bold;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 8px;
  margin-top: 10px;
  transition: background-color 0.3s, transform 0.2s;
}

button[type="submit"]:hover {
  background-color: #ff6666;
  transform: scale(1.05);
}

/* Modal Footer (if needed) */
.modal-footer {
  border-top: none;
  justify-content: center;
  padding-top: 10px;
}

/* Additional Aesthetic Enhancements */
.modal-backdrop {
  backdrop-filter: blur(6px);
}

@media (max-width: 768px) {
  .social-icons {
    gap: 10px;
    width: 150px; /* Adjust size for smaller screens */
  }
  .social-icon {
    font-size: 25px; /* Reduce icon size for smaller screens */
  }
}

/* Buttons */
button {
  background: #ff4e50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  transition: 0.3s;
}

button:hover {
  background: #e43a3a;
  box-shadow: 0 5px 15px rgba(228, 58, 58, 0.4);
}
/* ---------close button css ----------- */
.modal-header .btn-close {
  background-color: transparent;
  border: none;
  position: relative;
}

.modal-header .btn-close:hover,
.modal-header .btn-close:focus {
  background-color: rgba(0, 0, 0, 0.1); /* Light background on hover */
  outline: none;
}

.modal-header .btn-close::before,
.modal-header .btn-close::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 2px;
  background-color: #000; /* Ensure the "X" stays visible */
  transform-origin: center;
}

.modal-header .btn-close::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.modal-header .btn-close::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.modal-header .btn-close:hover::before,
.modal-header .btn-close:hover::after {
  background-color: #800000; /* Change to match your theme */
}

/* --------------- about-page-css---------------------  */
/* Hero Section */
.about-hero-section {
  background-color: #f4f4f4; /* Light gray background for simplicity */
  padding: 80px 20px;
  text-align: center;
  color: #333; /* Neutral dark gray for text */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.about-title {
  font-weight: bold;
  font-size: 3rem; /* Adjusted for clarity */
  margin-bottom: 20px;
  color: #2c3e50; /* Dark gray-blue */
  text-transform: uppercase;
}

.about-description {
  max-width: 800px;
  margin: 0 auto;
  font-size: 1.2rem;
  line-height: 1.8;
  color: #555; /* Medium gray for softer text */
}

/* Content Section */
.about-content-section {
  padding: 60px 20px;
  background-color: #ffffff; /* Clean white background */
  color: #333;
  border-top: 1px solid #ddd; /* Subtle separator */
  border-bottom: 1px solid #ddd;
}

.about-content-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  flex-wrap: wrap;
}

.about-content-reverse {
  flex-direction: row-reverse;
}

.about-image {
  flex: 1;
  max-width: 600px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: transform 0.3s ease;
}

.about-image:hover {
  transform: scale(1.03); /* Slight zoom effect */
}

.about-content-text {
  flex: 1;
  max-width: 600px;
}

.about-heading {
  font-size: 2.2rem;
  font-weight: bold;
  color: #34495e; /* Dark gray-blue */
  margin-bottom: 30px;
}

.about-content-text p {
  margin-bottom: 15px;
  line-height: 1.6;
  font-size: 1.1rem;
  color: #555;
}

/* Aim Section */
.about-aim-section {
  padding: 60px 20px;
  background-color: #f8f9fa; /* Very light gray */
  color: #333;
}

.about-heading {
  font-size: 2.2rem;
  margin-bottom: 20px;
  color: #2c3e50; /* Dark gray-blue */
}

.about-content-text p {
  color: #555;
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 15px;
}

/* Benefits Section */
.about-benefits-section {
  padding: 60px 20px;
  background-color: #ffffff; /* Clean white background */
  color: #333;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.about-benefits-heading {
  font-size: 2.2rem;
  font-weight: bold;
  text-align: center;
  color: #34495e; /* Dark gray-blue */
  margin-bottom: 30px;
}

.about-benefits-image-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

.about-benefits-image {
  flex: 1;
  max-width: 500px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.about-benefits-text {
  flex: 1;
  padding: 40px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05); /* Subtle shadow for depth */
  max-width: 600px;
  background-color: #f4f4f4; /* Light gray for contrast */
}

.about-benefits-text ul {
  list-style-type: none;
  padding: 0;
}

.about-benefits-text li {
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 15px;
  line-height: 1.6;
  position: relative;
  padding-left: 35px; /* Space for icons */
}

.about-benefits-text li::before {
  content: "✔"; /* Checkmark icon */
  color: #3498db; /* Blue accent */
  font-size: 1.2rem;
  position: absolute;
  left: 10px;
  top: 0;
}

/* Footer Section */
.about-footer-section {
  background-color: #2c3e50; /* Dark gray-blue */
  padding: 20px;
  text-align: center;
  color: #bdc3c7; /* Soft gray text */
}

.about-footer-text {
  font-size: 1rem;
  color: #ecf0f1; /* Light gray */
}

/* Responsiveness */
@media (max-width: 768px) {
  .about-title {
    font-size: 2.5rem;
  }

  .about-description {
    font-size: 1rem;
  }

  .about-content-wrapper {
    flex-direction: column;
    text-align: center;
  }

  .about-image {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .about-content-text {
    max-width: 100%;
  }

  .about-heading {
    font-size: 2.2rem;
    font-weight: bold;
    text-align: center;
    color: #34495e; /* Dark gray-blue */
    margin-bottom: 30px;
  }

  .about-benefits-heading {
    font-size: 1.8rem;
  }
}

/* ------------  */

.about-content-section {
  padding: 60px 20px;
  background-color: #ffffff; /* Clean white background */
  color: #333;
}

.about-content-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 30px;
  flex-wrap: wrap;
}

.about-content-text {
  flex: 1;
  max-width: 600px;
}

.about-image {
  flex: 1;
  max-width: 500px; /*Adjust size as needed*/
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.about-image:hover {
  transform: scale(1.03); /* Slight zoom effect */
}

@media (max-width: 768px) {
  .about-content-wrapper {
    flex-direction: column;
    text-align: center;
  }
  .about-content-section {
    padding-top: 10px;
  }

  .about-image {
    /* margin-top: 0; */
    max-width: 100%;
  }
}

/* highlight word in about us page */
.imp-word-col {
  color: #800000;
}

/*? ----------------------css for carrer page---------------- */

.cards-Container {
  padding: 20px;
  gap: 20px;
  /* display: flex; */
  display: grid;
  flex-direction: row;
  flex-wrap: wrap;
  grid-template-columns: repeat(4, 2fr);
  /* background: #e7d9d9; */
}

/* Card Container */
.job-card {
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  font-family: Arial, sans-serif;
  margin: 0 auto;
  background-color: #f5cfcf;
}

/* Image Section */
.job-card .image-container {
  position: relative;
}

.job-card img {
  width: 100%;
  height: auto;
  padding: 2px;
  border-radius: 10px;
}

.job-card .badge-for-more {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #00b894;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
}

/* Content Section */
.job-card .content-container {
  padding: 20px;
}

.job-card .badge-apartment {
  display: inline-block;
  background-color: #00cec9;
  /* background-color: #800000; */
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 10px;
  cursor: pointer;
}

.job-card .price {
  color: #27ae60;
  font-size: 24px;
  font-weight: bold;
  margin: 5px 0;
}

.job-card .title {
  font-size: 18px;
  font-weight: bold;
  margin: 5px 0 10px;
}

.job-card .address {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;
}

.job-card .details-container {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  font-size: 14px;
  color: #555;
}

.job-card .detail-item {
  display: flex;
  align-items: center;
}

.job-card .icon {
  margin-right: 5px;
  color: #27ae60;
}

/* Media Queries for Responsive Grid */
@media (max-width: 1200px) {
  /* Adjust to 3 cards per row */
  .cards-Container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 992px) {
  /* Adjust to 2 cards per row */
  .cards-Container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  /* Adjust to 1 card per row for tablets and smaller screens */
  .cards-Container {
    grid-template-columns: 1fr;
  }

  /* Add margin and reduce box shadow for smaller screens */
  .job-card {
    margin: 10px auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
}

@media (max-width: 480px) {
  /* Smaller padding and font adjustments for very small devices */
  .job-card .content-container {
    padding: 15px;
  }

  .job-card .price {
    font-size: 20px;
  }

  .job-card .title {
    font-size: 16px;
  }

  .job-card .details-container {
    flex-direction: column;
    gap: 5px;
  }
}

/*?--------------- carrer page contact detail  css---------------------------------  */
/* ContactCard Component Styles */

.contact-card-container {
  /* margin: 20px auto; */
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.contact-card-header {
  text-align: center;
  margin-bottom: 20px;
}
.contact-card-quote {
  text-align: center;
  color: black;
  margin: 10px;
  /* margin-bottom: 20px; */
}
.contact-card-quote {
  position: relative;
  z-index: 2;
  text-align: center;
  padding: 5px 0;
  color: #333;
  font-size: 1.15rem;
  font-weight: bold;
}

.contact-card-header h2 {
  font-size: 2.5rem;
  color: #333;
  margin: 0;
}

.contact-card-help {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.contact-card-help-item {
  flex: 1;
  margin: 10px;
  min-width: 200px;
}

.contact-card-help-item h3 {
  font-size: 1.2rem;
  color: #444;
  margin-bottom: 5px;
}

.contact-card-help-item p,
.contact-card-help-item span,
.contact-card-help-item a {
  font-size: 1rem;
  color: #666;
  text-decoration: none;
}

.contact-card-help-item a:hover {
  color: #ff4a4a;
}

.contact-card-offices {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
}

.contact-card-office-item {
  flex: 1;
  margin: 10px;
  min-width: 250px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.contact-card-office-item h3 {
  font-size: 1.3rem;
  color: #333;
  margin-bottom: 10px;
}

.contact-card-office-item p {
  font-size: 1rem;
  color: #555;
  /* line-height: 1.6; */
}

.contact-card-office-item span {
  display: block;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 10px;
}

.contact-card-email {
  display: inline-block;
  margin-top: 10px;
  font-size: 1rem;
  color: #ff4a4a;
  text-decoration: none;
}

.contact-card-email:hover {
  text-decoration: underline;
}

.contact-card-branch-offices {
  text-align: center;
}

.contact-card-branch-offices p {
  font-size: 1rem;
  color: #555;
}

.contact-card-view-all {
  color: #ff4a4a;
  font-weight: bold;
  text-decoration: none;
}

.contact-card-view-all:hover {
  text-decoration: underline;
}

/** ------------ css for upper carrier oppurtunities-------------  */
/* Container */
.contact-card-container {
  position: relative;
  width: 100%;
  /* background: #fff; */
  background: #e7d9d9;
  overflow: hidden;
}

/* Top 70% Design */
.contact-card-design {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 70vh; /* Covers 70% of the height */
  background: linear-gradient(to right, #fbd3e9, #fddfc3);
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  z-index: 1;
}

.contact-card-circles .circle {
  position: absolute;
  background-color: rgba(255, 99, 71, 0.6); /* Semi-transparent red */
  border-radius: 50%;
}

.circle-small {
  width: 80px;
  height: 80px;
  top: 12%;
  left: 5%;
}

.circle-large {
  width: 120px;
  height: 120px;
  top: -10%;
  right: 10%;
}

/* Header Section */
.contact-card-header {
  position: relative;
  z-index: 2;
  text-align: center;
  padding: 20px 0;
  color: #333;
  font-size: 1.8rem;
  font-weight: bold;
}

/* Quick Help Section */
.contact-card-help {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-evenly;
  /* padding: 20px 10px; */
  padding: 10px 5px;
  background-color: rgba(212, 210, 210, 0.642);
}

.contact-card-help-item {
  text-align: center;
}

.contact-card-help-item h3 {
  margin: 0 0 10px;
  color: #555;
  font-size: 1.2rem;
}

.contact-card-help-item p,
.contact-card-help-item span,
.contact-card-help-item a {
  color: #777;
  font-size: 1rem;
  text-decoration: none;
}

/* Office Information */
.contact-card-offices {
  position: relative;
  z-index: 2;
  padding: 20px;
}

.contact-card-office-item {
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.contact-card-office-item h3 {
  color: #444;
  font-size: 1.3rem;
}

.contact-card-office-item p,
.contact-card-office-item span {
  color: #666;
}

.contact-card-email {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.contact-card-branch-offices {
  text-align: center;
  margin: 20px 0;
  color: #666;
}

.contact-card-view-all {
  color: #007bff;
  font-weight: bold;
  text-decoration: none;
}

/* Responsive Design */
@media (max-width: 768px) {
  .contact-card-help {
    flex-direction: column;
    align-items: center;
  }
  .circle-small,
  .circle-large {
    display: none; /* Hide circles on small screens */
  }
  .contact-card-quote {
    font-size: 0.75rem;
  }
}

/** --------------------- modal popup message css on apply here click option  */
.job-card-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.job-card-modal-container {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

.job-card-modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

/* 1st css  */
.job-card-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.job-card-modal-container {
  background: #fff;
  padding: 20px;
  border-radius: 12px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  position: relative;
  border: 3px solid #800000; /* Maroon border for emphasis */
}

.job-card-modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  color: #800000;
  cursor: pointer;
  font-weight: bold;
}

.job-card-modal-close-button:hover {
  color: rgba(255, 0, 0, 0.785);
  background-color: rgb(232, 100, 100);
}

.job-card-modal-header {
  color: #800000;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.job-card-modal-header .icon {
  font-size: 28px;
  color: red;
}

.job-card-modal-content {
  font-size: 16px;
  color: #333;
}

.job-card-modal-content p {
  margin: 10px 0;
}

.job-card-modal-content ul {
  margin: 10px 0;
  padding-left: 20px;
  color: #800000;
}

.job-card-modal-content ul li {
  margin-bottom: 8px;
  list-style: disc;
}

.job-card-modal-footer {
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid #ddd;
  text-align: center;
}

.job-card-modal-footer span {
  font-size: 14px;
  color: #800000;
}

.job-card-modal-footer a {
  color: rgb(218, 153, 164);
  text-decoration: underline;
  font-weight: bold;
}

.job-card-modal-footer a:hover {
  color: red;
}

/** --------------IT SECTION css codes ------------------------ */
.motivational-section {
  position: relative;
  width: 100%;
  height: 460px; /* Reduced height */
  background-image: url("./assets/images/section-one.jpg");
  /* background-size: cover; */
  background-size: contain;
  /* background-position: center; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: Arial, sans-serif;
  overflow: hidden;
  padding: 20px;
}

.quote-container {
  text-align: center;
  max-width: 85%;
  padding: 10px;
}

.quote {
  font-size: 1.8rem; /* Further reduced size */
  font-weight: bold;
  display: inline-block;
  line-height: 1.3;
}

.author {
  font-size: 0.9rem; /* Reduced slightly for better balance */
  font-style: italic;
  margin-top: 15px;
}

.letter {
  display: inline-block;
  opacity: 0;
  transform: translateX(70px); /* Adjusted animation start */
  animation: throwIn 0.5s forwards;
  font-size: 3rem; /* Reduced font size for smooth appearance */
  font-weight: 700; /* Slightly lighter font weight */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

/* Keyframe Animation */
@keyframes throwIn {
  0% {
    opacity: 0;
    transform: translateX(70px);
  }
  50% {
    opacity: 1;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Responsive Design */

/* Tablets and small laptops */
@media (max-width: 1024px) {
  .motivational-section {
    height: 450px;
    padding: 15px;
  }
  .quote {
    font-size: 1.7rem; /* Balanced for medium screens */
  }
  .letter {
    font-size: 2.8rem; /* Further reduction for better fit */
  }
  .author {
    font-size: 0.85rem;
  }
}

/* Mobile devices */
@media (max-width: 768px) {
  .motivational-section {
    height: 400px;
    padding: 10px;
  }
  .quote {
    font-size: 1.6rem; /* Reduced size for small screens */
  }
  .letter {
    font-size: 2.5rem; /* Further reduced for smoother appearance */
    transform: translateX(50px);
  }
  .author {
    font-size: 0.8rem;
  }
}

/* Small mobile screens */
@media (max-width: 480px) {
  .motivational-section {
    height: 350px;
    padding: 5px;
  }
  .quote {
    font-size: 1.5rem; /* Reduced to maintain readability */
  }
  .letter {
    font-size: 2.3rem; /* Small but still legible */
    transform: translateX(30px);
  }
  .author {
    font-size: 0.75rem;
  }
}


/* *section-two */

.section-it-two {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.section-two-container {
  display: flex;
  flex: 1;
  padding: 20px;
}

.screen-layouts-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
  padding: 40px;
  text-align: center;
  background-color: #f5faff;
}

.screen-layouts-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #1f2937;
}

.screen-layouts-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.screen-layout-item {
  background-color: #fff;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
}

.screen-layout-icon {
  font-size: 40px;
  margin-bottom: 10px;
  color: #800000; /* Unified color */
}

.screen-layout-text {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #800000; /* Unified color */
}

.screen-layout-item span {
  font-size: 14px;
  color: #6b7280;
}

.section-two-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.section-two-image {
  max-width: 100%;
  height: auto;
}

/* Media Queries */

/* Tablet Screens */
@media (max-width: 1024px) {
  .screen-layouts-grid {
    /* grid-template-columns: 1fr; Single column layout */
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Mobile Screens */
/* Mobile Screens */
@media (max-width: 768px) {
  .section-it-two {
    flex-direction: column; /* Stacks sections vertically */
  }

  .section-two-right {
    order: -1; /* Moves the image to the top */
    margin-bottom: 2rem; /* Adds spacing below the image */
  }

  .section-two-container {
    margin-right: 0; /* Remove extra spacing */
    flex-direction: column;
  }

  .screen-layouts-title {
    font-size: 20px;
  }
}

/* Small Mobile Screens */
@media (max-width: 480px) {
  .section-it-two {
    flex-direction: column;
  }
  .screen-layouts-grid {
    grid-template-columns: 1fr; /* Single column layout */
  }

  .section-two-right {
    order: -1; /* Moves the image to the top */
    margin-bottom: 1rem; /* Adds spacing below the image */
  }

  .screen-layouts-container {
    padding: 10px;
    margin-right: 0rem;
  }

  .screen-layouts-title {
    font-size: 18px;
  }

  .screen-layout-item {
    padding: 10px;
  }

  .screen-layout-icon {
    font-size: 30px;
  }

  .screen-layout-text {
    font-size: 14px;
  }

  .section-two-image {
    max-width: 90%; /* Ensure image fits smaller screens */
  }
}

/** -------------- services section 3-----------  */
/* General Styles */
.cards-section {
  padding: 2rem 5vw;
  background-color: rgb(128, 0, 0, 0.8);
  margin-bottom: 3rem;
  /* background-color: #d38c8c; */
}
.cards-section .cards-screen-title {
  text-align: center;
  padding: 20px 0;
  color: #fff5f5;
  font-size: 1.8rem;
  font-weight: 900;
  padding-top: 5px;
  padding-bottom: 15px;
}
.badge-for-more {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #800000;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
}

.cards-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 0;
  padding: 0;
  list-style-type: none;
  /* background-color: #00b894; */
}

.cards-list-item {
  list-style: none;
  /* background-color: #000; */
}

.cards-link {
  position: relative;
  display: block;
  height: 100%;
  text-decoration: none;
  border-radius: 20px;
  overflow: hidden;
  /* border: 1px solid; */
  background-color: #b99898;
}

.cards-image {
  width: 100%;
  height: auto;
  display: block;
}

.cards-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: #fff;
  border-radius: 20px;
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
}

.cards-link:hover .cards-overlay {
  transform: translateY(0);
}

.cards-header {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 1.2rem;
  background-color: #fff;
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
}

.cards-link:hover .cards-header {
  transform: translateY(0);
}

.cards-arc {
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 100%;
  right: 0;
}

.cards-thumb {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.cards-title {
  font-size: 20px;
  font-weight: 800;
  color: #800000;
  margin: 0 0 0.3rem;
}

.cards-tagline {
  font-size: 0.85rem;
  color: #d7bdca;
}

.cards-status {
  font-size: 0.8rem;
  color: rgb(209, 67, 67);
  font-weight: 600;
}

.cards-description {
  padding: 0 1.5rem 1.5rem;
  color: #d7bdca;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .cards-list {
    grid-template-columns: 1fr;
  }

  .cards-title {
    font-size: 0.9rem;
  }

  .cards-description {
    font-size: 0.85rem;
  }
}

@media (max-width: 480px) {
  .cards-thumb {
    width: 40px;
    height: 40px;
  }

  .cards-title {
    font-size: 0.85rem;
  }

  .cards-description {
    font-size: 0.8rem;
    padding: 0 1rem 1rem;
  }
}

/** -------------------stats section css ------------------ */
/* Stats Section */
.stats-section {
  padding: 40px 20px;
}

.stats-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.stat-item {
  text-align: center;
  color: #000; /* Black or dark text */
  padding: 0 20px;
  position: relative;
}

.stat-item:not(:last-child)::after {
  content: "";
  position: absolute;
  right: -60px;
  top: 50%;
  transform: translateY(-50%);
  height: 40px;
  width: 2px;
  background-color: #800000; /* Maroon divider line */
}

.stat-value {
  font-size: 32px;
  font-weight: bold;
  margin: 0;
  color: #800000; /* Highlight the numbers with maroon */
}

.stat-label {
  font-size: 16px;
  margin: 5px 0 0;
  color: #555; /* Muted gray for labels */
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .stats-container {
    flex-direction: column;
    gap: 20px;
  }

  .stat-item:not(:last-child)::after {
    display: none; /* Hide dividers on small screens */
  }
}

/** section 3 */

/* Section 3 */
.section-three {
  /* padding: 40px 20px; */
  background-color: #f9f9f9;
}

/* Features Container */
.features-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 40px;
}

.feature-item {
  text-align: center;
  max-width: 300px;
}

.feature-icon {
  font-size: 40px;
  color: #800000;
  margin-bottom: 15px;
}

.feature-title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.feature-description {
  font-size: 14px;
  color: #555;
  line-height: 1.5;
}

/* Stats Container */
.stats-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.stat-item {
  text-align: center;
  color: #333;
}

.stat-icon {
  font-size: 36px;
  color: #800000; /* Maroon color for icons */
  margin-bottom: 10px;
}

.stat-value {
  font-size: 28px;
  font-weight: bold;
  color: #800000;
}

.stat-label {
  font-size: 16px;
  color: #555;
  font-weight: 800;
}

/* Responsive Design */
@media (max-width: 768px) {
  .features-container,
  .stats-container {
    flex-direction: column;
    align-items: center;
  }

  .feature-item,
  .stat-item {
    margin-bottom: 20px;
  }
}

/* company details of marquee tag*/
/* Container */
.marquee-container {
  background-color: #800000; /* Deep blue background */
  color: white;
  padding: 20px;
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 0.5px;
  border-radius: 1%;
}

.marquee-title {
  font-size: 1.5rem;
  margin-bottom: 15px;
  font-weight: bold;
}

/* Marquee */
.marquee {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.marquee-content {
  display: flex;
  gap: 30px;
  animation: marquee-scroll 15s linear infinite; /* Animation */
}

.marquee-item {
  display: inline-block;
  padding: 10px 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  flex-shrink: 0;
  transition: transform 0.3s ease;
}

.marquee-item:hover {
  transform: scale(1.1);
}

.partner-logo {
  max-width: 100px;
  max-height: 50px;
  object-fit: contain;
}

/* Keyframes for scrolling effect */
@keyframes marquee-scroll {
  from {
    transform: translateX(100%); /* Start fully visible */
  }
  to {
    transform: translateX(-100%); /* Move out of view to the left */
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .marquee-content {
    gap: 15px;
  }

  .partner-logo {
    max-width: 80px;
  }
}

/* *section -four tech stack */
/* Root Section */
.section-five {
  background: linear-gradient(135deg, #800000, #4d0000);
  color: #ffb6c1; /* Light Pink Text */
  text-align: center;
  padding: 0px 20px;
  font-family: "Arial", sans-serif;
  overflow: hidden;
  margin-top: 5rem;
  padding-bottom: 40px;
}

/* Title */
.section-five__title {
  font-size: 2.5rem;
  margin-bottom: 30px;
  animation: fadeInDown 1s ease-in-out;
  color: #ffb6c1; /* Light Pink Title */
}

/* Categories Grid */
.section-five__categories {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(200px, 1fr)
  ); /* Dynamically adjust columns */
  gap: 30px; /* Space between columns */
  text-align: left;
  margin-top: 20px;
}

/* Individual Category */
.section-five__category {
  background: rgba(255, 255, 255, 0.05); /* Subtle background overlay */
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #ff0000; /* Red Border */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.section-five__category-title {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #ffb6c1; /* Light Pink */
  text-transform: capitalize;
}

/* Technologies List */
.section-five__tech-list {
  display: flex;
  flex-direction: column; /* Technologies are listed vertically */
  gap: 10px;
}

.section-five__tech {
  background: #4d0000;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  color: #ffb6c1;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: fadeInUp 0.5s ease-in-out;
  border: 2px solid transparent;
  transition: transform 0.3s ease-in-out, border-color 0.3s;
  border: 1px solid red;
}

.section-five__tech:hover {
  transform: translateY(-5px);
  background: #ffb6c1;
  color: #800000;
  border-color: #ff0000;
}

/* Call-to-Action */
.section-five__cta {
  margin-top: 30px;
}

.section-five__cta button {
  background: #ff0000;
  color: #fff;
  border: none;
  padding: 10px 25px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  animation: bounce 2s infinite;
  transition: background 0.3s, transform 0.3s;
}

.section-five__cta button:hover {
  background: #ffb6c1;
  color: #800000;
  transform: scale(1.05);
}

/* Keyframe Animations */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .section-five__title {
    font-size: 2rem;
  }

  .section-five__categories {
    grid-template-columns: repeat(
      auto-fit,
      minmax(150px, 1fr)
    ); /* Adjust column count for smaller screens */
  }

  .section-five__category-title {
    font-size: 1.3rem;
  }

  .section-five__tech {
    font-size: 0.9rem;
    padding: 8px 15px;
  }
}

/** section five approaces  */
/* Timeline Container */
.timeline-container {
  margin-top: 2.5rem;
  padding: 12px;
  background: #f8f9fa;
  text-align: center;
  position: relative;
}

.timeline-title {
  font-size: 3rem;
  color: #333;
  margin-bottom: 30px;
  font-weight: bold;
}

/* Timeline Layout */
.timeline {
  display: flex;
  flex-direction: column;
  gap: 50px;
  position: relative;
}

/* Alternating Timeline Steps */
.timeline-step {
  display: flex;
  align-items: center;
  position: relative;
  width: 50%;
}

.timeline-step.left {
  flex-direction: row-reverse;
  text-align: right;
  align-self: flex-start;
}

.timeline-step.right {
  text-align: left;
  align-self: flex-end;
}

.circle-icon {
  width: 70px;
  height: 70px;
  background: #ffffff;
  border: 3px solid #4caf50;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  animation: pulse 2s infinite ease-in-out;
}

.step-icon {
  font-size: 2rem;
  color: #4caf50;
  animation: bounce 3s infinite alternate;
}

.step-content {
  padding: 8px 12px;
}

.step-title {
  font-size: 1.4rem;
  color: #800000;
  font-weight: 900;

}

.step-description {
  font-weight: 800;
  font-size: 1rem;
  color: #666;
  width: 18rem;
  font-style: oblique;
}

@media (max-width: 768px) {
  .step-description {
    width: auto;
  }
}

/* Pulse Animation */
@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(76, 175, 80, 0.4);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 0 20px rgba(76, 175, 80, 0.6);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(76, 175, 80, 0.4);
  }
}

/* Bounce Animation */
@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* Connector Animation */
@keyframes dashAnimation {
  to {
    stroke-dashoffset: -20;
  }
}

/* -------------------- Home Page Section One ------------ */
.section-one {
  display: flex;
  width: 100%;
  height: 400px; /* Adjusted height */
  overflow: hidden;
  gap: 10px;
}

.banner {
  flex: 2; /* Smaller proportion */
  height: 100%; /* Adjusts with section-one */
}

.slideshow-container {
  position: relative;
  width: 100%;
  max-width: 800px;
  height: auto; /* Allow height to adjust based on images */
}

.slideshow img {
  width: 100%;
  height: auto;
  object-fit: contain; /* Ensures images fit without cropping */
  border-radius: 8px;
}

.sidebar {
  flex: 1; /* Takes 1/4 of the space */
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background: #f8f8f8;
  justify-content: center;
}

.product-search,
.business-growth {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  color: white;
  min-height: 150px;
}

.product-search {
  background: linear-gradient(135deg, #ff6b81, #ff4757);
}

.business-growth {
  background: linear-gradient(135deg, #a8323e, #800000);
}

.product-search p,
.business-growth p {
  font-size: 1.1rem;
  margin: 5px 0;
}

.product-search:hover,
.business-growth:hover {
  transform: translateY(-3px);
  transition: transform 0.3s ease-in-out;
}

@media (max-width: 600px) {
  .section-one {
    flex-direction: column;
    height: auto;
  }

  .banner {
    flex: none;
    width: 100%;
    height: auto;
  }

  .sidebar {
    flex: none;
    gap: 10px;
    padding: 10px;
    width: 100%;
  }

  .product-search,
  .business-growth {
    padding: 15px;
  }
}

@media (min-width: 601px) and (max-width: 992px) {
  .section-one {
    flex-direction: column;
    height: auto;
  }

  .banner {
    flex: none;
    width: 100%;
    height: auto;
  }

  .sidebar {
    flex: none;
    flex-direction: row;
    gap: 15px;
    padding: 15px;
  }

  .product-search,
  .business-growth {
    flex: 1;
    padding: 20px;
  }
}

@media (min-width: 993px) {
  .section-one {
    height: auto;
  }
}



/* HOME  */
/** testimonial section css ---------------- */
/* Testimonial Section */
.testimonial-section {
  padding: 60px 40px;
  background: linear-gradient(135deg, #f8f9fa, #eaeaea);
  border-radius: 12px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
}

/* Header */
.testimonial-header-main {
  text-align: center;
  margin-bottom: 30px;
}

.testimonial-title-main {
  font-size: 2.5rem;
  font-weight: bold;
  color: #800000;
}

.testimonial-subtitle-main {
  font-size: 1.2rem;
  color: #555;
  max-width: 600px;
  margin: 0 auto;
}

/* Testimonials Container */
.testimonials-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  max-width: 100%;
}

/* Slider */
.testimonial-slider {
  width: 80%;
  overflow: hidden;
  position: relative;
}

/* Wrapper */
.testimonial-wrapper {
  display: flex;
  transition: transform 0.6s ease-in-out;
  gap: 20px;
}

/* Testimonial Card */
.testimonial-card {
  flex: 0 0 30%;
  background-color: #e8dee0;
  color: #333;
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.testimonial-card:hover {
  transform: translateY(-6px);
  box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.2);
}

/* Quote Text */
.testimonial-text {
  font-size: 1.1rem;
  font-weight: 500;
  color: #333;
  font-style: italic;
  margin-bottom: 15px;
  position: relative;
}

/* Divider */
.testimonial-divider {
  width: 50px;
  height: 2px;
  background-color: #800000;
  margin: 10px 0;
}

/* Name & Title */
.testimonial-name {
  font-size: 1rem;
  font-weight: bold;
  color: #800000;
}

.testimonial-title {
  font-size: 0.9rem;
  color: #555;
  font-style: italic;
}

/* Arrows */
.arrow {
  background: none;
  border: none;
  color: #800000;
  font-size: 30px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: color 0.3s;
}

.arrow:hover {
  color: black;
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}

/* Responsive Styles */

/* Tablets */
@media (max-width: 1024px) {
  .testimonial-slider {
    width: 90%;
  }

  .testimonial-card {
    flex: 0 0 45%;
  }
}

/* Large Phones */
@media (max-width: 768px) {
  .testimonial-slider {
    width: 95%;
  }

  .testimonial-card {
    flex: 0 0 100%;
  }

  .arrow {
    font-size: 26px;
  }
}

/* Small Phones */
@media (max-width: 480px) {
  .testimonial-slider {
    width: 100%;
  }

  .testimonial-card {
    flex: 0 0 100%;
    padding: 20px;
  }

  .arrow {
    font-size: 24px;
  }
}



/**----------------* footer component section css ------------------- */
/* Footer Main Section */
.footer-main-section {
  background-color: #800000; /* Deep Red Background */
  color: #ffffff;
  padding: 32px 20px;
  padding-bottom: 20px;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Top Section */
.footer-main-section-top {
  text-align: center;
  margin-bottom: 25px;
}

.footer-main-section-heading {
  font-size: 2rem; /* Larger Heading */
  margin-bottom: 20px;
}

.footer-main-section-highlight {
  color: #ff6b81; /* Pink Highlight */
  font-weight: bold;
}

.footer-main-section-subscribe {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.footer-main-section-input {
  padding: 12px;
  width: 350px;
  border: none;
  border-radius: 5px;
}

.footer-main-section-button {
  background-color: #ff4757; /* Brighter Red Button */
  color: #ffffff;
  border: none;
  padding: 12px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.footer-main-section-button:hover {
  background-color: #ff6b81; /* Slightly lighter pink on hover */
}

/* Middle Section */
.footer-main-section-middle {
  display: flex;
  justify-content: space-around;
  /* margin-bottom: 20px; */
  /* flex-wrap: wrap; */
  gap: 30px;
}

/* About Us Section */
.footer-main-section-about {
  max-width: 400px;
}

.footer-main-section-about h3 {
  font-size: 2.5rem;
  margin-bottom: 15px;
  color: #ff6b81; /* Pink Section Heading */
}

.footer-main-section-about p {
  font-size: 1.15rem;
  line-height: 1.6;
}

/* Quick Links Section */
.footer-main-section-links {
  max-width: 300px;
}

.footer-main-section-links h3 {
  font-size: 2.5rem;
  margin-bottom: 15px;
  color: #ff6b81; /* Pink Section Heading */
}

.footer-main-section-links ul {
  list-style: none;
  padding: 0;
}

.footer-main-section-links li {
  margin-bottom: 10px !important;
  font-size: 1.15rem ;
  cursor: pointer !important;
}

.footer-main-section-links li:hover {
  text-decoration: underline ;
  color: #ff4757 ;
}
/* Bottom Section */
.footer-main-section-bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ffffff; /* Clear Border */
  padding-top: 15px;
  /* margin-top: 25px; */
  text-align: center;
  gap: 10px; /* Space between items */
}

/* Bottom Logo */
.footer-bottom-logo {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 10px; */
}

.footer-logo-image {
  /* width: 120px; */
  width: 60px;
  height: auto;
  object-fit: contain;
}

/* Text in Bottom Section */
.footer-main-section-bottom p {
  flex: 1;
  font-size: 1rem;
  margin: 0;
  color: #ffffff; /* Ensure visible text */
}

/* Social Icons */
.footer-main-section-icons {
  flex: 1;
  text-align: center;
  /* margin-top: 10px; */
}

.footer-main-section-icons a {
  font-size: 1.4rem;
  margin: 0 10px;
  cursor: pointer;
  color: #ffffff; /* White Icons */
  text-decoration: none;
}

.footer-main-section-icons a:hover {
  color: #ff6b81; /* Pink Hover Effect */
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-main-section-bottom {
    /* flex-direction: column; */
    text-align: center;
  }

  .footer-bottom-logo {
    margin-bottom: 15px;
  }

  .footer-main-section-bottom p {
    /* margin-bottom: 12px; */
    font-size: 12px;
  }

  .footer-main-section-icons {
    margin-top: -10px;
    display: flex;
  }
  .footer-logo-image{
    width: 30px;
  }

  /**--------------- new css added */
  
  .footer-main-section-middle p{
    font-size: 12px;
    width: 10rem;
  }
  .footer-main-section-links li{
    font-size: 12px;
  }
  .footer-main-section-links h3{
    font-size: 25px;
  }
  .footer-main-section-about h3 {
    font-size: 25px;
  }
  .footer-main-section-input {
    padding: 10px;
    }
  
}

@media (max-width: 480px) {
  .footer-main-section-bottom p {
    font-size: 8px;
  }

  .footer-main-section-icons a {
    font-size: 1.2rem;
  }
  .footer-main-section-button {
    padding: 3px 13px;
  }
}



/* *-----------------section-map ------------- css */
.section-map {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  padding: 40px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;
}

.map-container {
  flex: 1;
  max-width: 45%;
  text-align: center;
  padding: 20px;
}

.map-image {
  max-width: 100%;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.map-image:hover {
  transform: scale(1.05);
}

.content-container-map {
  flex: 1;
  max-width: 50%;
  padding: 20px;
  background: #f8f8f8;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.content-header {
  font-size: 2.5rem;
  font-weight: bold;
  color: #800000;
  margin-bottom: 15px;
  text-align: left;
}

.content-list {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 1.2rem;
  color: #333;
  
}

.column {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 48%;
  
}

.column ul {
  padding: 0;
  margin: 0;
  
}

.column li {
  padding: 14px 20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  position: relative;
  font-weight: 500;
  margin-bottom: 5px;
}

.column li::before {
  content: "";
  width: 6px;
  height: 100%;
  background: #3498db;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px 0 0 8px;
}

.column li:hover {
  background: #f1f1f1;
  transform: translateX(8px);
}

.column li:hover::before {
  background: #e74c3c;
}

/* Apply only for screens between 1407px and 992px */
@media (max-width: 1407px) and (min-width: 992px) {
  .section-map {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 50px;
    background: linear-gradient(135deg, #eef2f3, #dbe9f1);
    border-radius: 10px;
    overflow: hidden;
  }

  .map-container {
    flex: 1;
    max-width: 50%;
    text-align: center;
    position: relative;
    z-index: 2;
  }

  .map-image {
    max-width: 90%;
    height: auto;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
  }

  .map-image:hover {
    transform: scale(1.08) rotate(2deg);
  }

  .content-container-map {
    flex: 1;
    max-width: 50%;
    padding: 25px;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    position: relative;
    transition: transform 0.3s ease;
  }

  .content-container-map:hover {
    transform: translateY(-5px) rotate(-1deg);
  }

  .content-header {
    font-size: 1.8rem;
    font-weight: bold;
    color: #34495e;
    margin-bottom: 20px;
    text-align: center;
  }

  .content-list {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .column {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 45%;
  }

  .column li {
    padding: 16px 22px;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.12);
    transition: all 0.3s ease-in-out;
    position: relative;
    font-weight: 500;
    text-align: center;
    border-left: 6px solid #2980b9;
  }

  .column li:hover {
    background: #f0f8ff;
    transform: translateY(-6px);
    border-left-color: #e74c3c;
    box-shadow: 0 6px 14px rgba(0, 0, 0, 0.2);
  }

  /* Add a floating, semi-transparent circle for aesthetics */
  .section-map::before {
    content: "";
    position: absolute;
    top: 10%;
    left: -50px;
    width: 150px;
    height: 150px;
    background: rgba(52, 152, 219, 0.2);
    border-radius: 50%;
    z-index: 1;
  }

  .section-map::after {
    content: "";
    position: absolute;
    bottom: 5%;
    right: -60px;
    width: 120px;
    height: 120px;
    background: rgba(231, 76, 60, 0.2);
    border-radius: 50%;
    z-index: 1;
  }
}


/* Responsive Adjustments */
@media (max-width: 992px) {
  .section-map {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .map-container,
  .content-container-map {
    max-width: 90%;
  }

  .content-list {
    flex-direction: column;
    align-items: center;
  }

  .column {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .map-image {
    width: 100%;
    max-width: 300px;
  }

  .content-header {
    font-size: 1.5rem;
    text-align: center;
  }

  .column li {
    text-align: center;
    padding: 12px;
  }
}



/** ------------- advertisement package css------------------  */

/* section-one css  */
/* Container for the entire section */
.package-section-one-container {
  padding: 40px;
  background-color: #f5f5f5;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Animated Heading */
.package-section-one-heading h1 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #800000; /* Dark red color */
  text-align: center;
  margin-bottom: 10px;
  animation: slideIn 1s ease-in-out forwards;
  opacity: 0; /* Initially hidden */
}

.package-section-one-heading p {
  font-size: 1.2rem;
  font-weight: 400;
  color: #555; /* Subtle gray */
  text-align: center;
  margin-bottom: 30px;
  animation: fadeIn 1.5s ease-in-out forwards;
  opacity: 0; /* Initially hidden */
}

/* Cards Container */
.package-section-one-button-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

/* Individual Card */
.package-section-one-card {
  padding: 20px;
  background-color: #ffffff;
  border: 2px solid #800000; /* Dark red border */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  animation: cardFadeIn 0.8s ease-in-out forwards;
  opacity: 0; /* Initially hidden */
  transform: translateY(20px); /* Slightly lower position */
  animation-delay: calc(0.1s * var(--card-index)); /* Staggered animation */
}

.package-section-one-card:hover {
  transform: scale(1.05); /* Slight zoom effect */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

/* Text in Cards */
.package-section-one-card h3 {
  font-size: 1.4rem;
  font-weight: 500;
  color: #800000; /* Dark red text */
  margin: 0;
  text-transform: capitalize; /* Capitalize product names */
  letter-spacing: 0.5px;
  line-height: 1.5;
}

/* Animations */
@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes cardFadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .package-section-one-heading h1 {
    font-size: 2rem;
  }

  .package-section-one-heading p {
    font-size: 1rem;
  }

  .package-section-one-card {
    padding: 15px;
  }

  .package-section-one-card h3 {
    font-size: 1.2rem;
  }
}


/**--------------- package-section-two css-------------------  */
/* General Section Styles */
.section-table {
  padding: 20px;
  margin: 0 auto;
  margin-top: 2rem;
  text-align: center;
  background: #f9f9f9;
}

.table-title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #800000; /* User's primary theme color */
}

/* Table Container for Responsiveness */
.table-container {
  overflow-x: auto;
  margin-top: 10px;
}

/* Table Styles */
.advertisement-table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
  font-size: 1.2rem;
  background-color: #fff;
}
.advertisement-table {
 color:hsl(0, 98%, 19%)
}

.advertisement-table th,
.advertisement-table td {
  border: 1px solid #ddd;
  padding: 10px 8px;
  text-align: center;
  word-wrap: break-word;
}

.advertisement-table thead {
  background-color: #800000; /* User's primary theme color */
  color: white;
}

.advertisement-table th {
  font-weight: bold;
}

/* Row Hover Effect */
.advertisement-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.advertisement-table tbody tr:hover {
  background-color: #ffe6e6;
  transition: background-color 0.3s ease-in-out;
}

/* Responsive Table for Mobile */
@media (max-width: 768px) {
  .advertisement-table thead {
    display: none;
  }

  .advertisement-table,
  .advertisement-table tbody,
  .advertisement-table tr,
  .advertisement-table td {
    display: block;
    width: 100%;
  }

  .advertisement-table tr {
    margin-bottom: 15px;
    border: 1px solid #ddd;
  }

  .advertisement-table td {
    text-align: right;
    padding-left: 50%;
    position: relative;
  }

  .advertisement-table td::before {
    content: attr(data-label);
    position: absolute;
    left: 10px;
    width: 45%;
    font-weight: bold;
    text-align: left;
    color: #800000;
  }

  .advertisement-table td:last-child {
    border-bottom: 0;
  }
}

/* Animation */
.advertisement-table tbody tr {
  transition: transform 0.2s ease-in-out;
}

.advertisement-table tbody tr:hover {
  transform: scale(1.02);
}



/**----------------------- it solution pages css-------------------------------  */
/* software development */
/* General Styling */
.softwareDev-container {
  width: 100%;
  background: white;
  color: #222;
  font-family: "Arial", sans-serif;
  padding: 3rem 8rem;
}

/* Header */
.softwareDev-header {
  text-align: left;
  margin-bottom: 2rem;
}

.softwareDev-title {
  font-size: 3rem;
  font-weight: bold;
  color: #800000;
}

.softwareDev-subtitle {
  font-size: 1.5rem;
  color: #444;
}

/* Image Section */
.softwareDev-images {
  display: flex;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.softwareDev-mainImage {
  flex: 2;
  position: relative;
}

.softwareDev-mainImage img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

/* Image Captions */
.softwareDev-mainImage .image-caption,
.softwareDev-sideImages .image-caption {
  text-align: center;
  font-size: 1rem;
  color: #555;
  margin-top: 0.5rem;
  font-style: italic;
}

/* Right Side Small Images */
.softwareDev-sideImages {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.softwareDev-sideImages img {
  width: 100%;
  border-radius: 10px;
}

/* Main Content */
.softwareDev-main {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

/* Sections */
.softwareDev-section {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background-color: pink;
}
.softwareDev-section:first-child{
}

.softwareDev-heading {
  font-size: 2.2rem;
  margin-bottom: 1rem;
  color: #800000;
  font-weight: bold;
}

.softwareDev-text {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #555;
}

/* List */
.softwareDev-list {
  list-style-type: disc;
  padding-left: 2rem;
  font-size: 1.2rem;
  color: #444;
}

.softwareDev-list li {
  font-weight: 600;
  margin-bottom: 0.5rem;
}

/* Proficiency Grid */
.softwareDev-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
}

.softwareDev-card {
  background: #fff;
  padding: 1.5rem;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.softwareDev-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  background: #b30000;
  color: white;
}


/* Responsive Design */
@media screen and (max-width: 1024px) {
  .softwareDev-container {
    padding: 2rem 4rem;
  }

  .softwareDev-images {
    flex-direction: column;
  }

  .softwareDev-sideImages {
    flex-direction: row;
    justify-content: space-between;
  }

  .softwareDev-sideImages img {
    width:48%;
  }
}

@media screen and (max-width: 768px) {
  .softwareDev-container {
    padding: 2rem;
  }

  .softwareDev-title {
    font-size: 2.5rem;
  }

  .softwareDev-subtitle {
    font-size: 1.3rem;
  }

  .softwareDev-images {
    flex-direction: column;
  }

  .softwareDev-sideImages {
    flex-direction: row; 
  }

  .softwareDev-sideImages img {
    /* width: 32%; */
    width: 80%;
  }

  .softwareDev-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}


/* new css  */
.slideshow-container.left-unique-section {
  position: relative;
  max-width: 100%;
  overflow: hidden;

}

.slideshow.left-unique-section {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slide.left-unique-section {
  width: 100%;
  height: auto;
  display: none;
  padding: 10px;
}

.slide.left-unique-section.active {
  display: block;
}

button.left-unique-section {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 20px;
}

.prev.left-unique-section {
  left: 10px;
}

.next.left-unique-section {
  right: 10px;
}

@media (max-width: 768px) {
  button.left-unique-section {
    font-size: 16px;
    padding: 5px;
  }
}



/* privay policy page css  */
/* General Section Styling */
.privacy-policy-section.pri-policy-additionally {
  font-family: Arial, sans-serif;
  padding: 60px 20px;
  background-color: #f4f4f9;
  color: #333;
  transition: all 0.3s ease-in-out;
}

/* Header Styling */
.privacy-policy-header.pri-policy-additionally {
  text-align: center;
  margin-bottom: 40px;
  animation: fadeIn 1s ease-in-out;
}

.privacy-policy-title.pri-policy-additionally {
  font-size: 2.5rem;
  font-weight: bold;
  color: #800000; /* #800000 color */
  transition: color 0.3s ease-in-out;
}

.privacy-policy-title.pri-policy-additionally:hover {
  color: #e74c3c; /* Red color on hover */
}

.privacy-policy-subtitle.pri-policy-additionally {
  font-size: 1.2rem;
  color: #7f8c8d;
}

/* Content Section */
.privacy-policy-content.pri-policy-additionally {
  max-width: 1000px;
  margin: auto;
}

/* Section Title */
.section-title.pri-policy-additionally {
  font-size: 1.8rem;
  font-weight: bold;
  margin-top: 30px;
  color: #ff66b2; /* Pink color */
  animation: slideInLeft 1s ease-in-out;
  transition: color 0.3s ease-in-out;
}

.section-title.pri-policy-additionally:hover {
  color: #e74c3c; /* Red color on hover */
}

/* Policy Text */
.policy-text.pri-policy-additionally {
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
  margin-bottom: 20px;
  transition: color 0.3s ease-in-out;
}

.policy-text.pri-policy-additionally a {
  color: #ff66b2; /* Pink color for links */
  text-decoration: none;
}

.policy-text.pri-policy-additionally a:hover {
  color: #800000; /* Dark red color on hover */
}

/* Mobile Responsive Styling */
@media (max-width: 768px) {
  .privacy-policy-section.pri-policy-additionally {
    padding: 40px 15px;
  }

  .privacy-policy-title.pri-policy-additionally {
    font-size: 2rem;
  }

  .privacy-policy-subtitle.pri-policy-additionally {
    font-size: 1rem;
  }

  .section-title.pri-policy-additionally {
    font-size: 1.6rem;
  }

  .policy-text.pri-policy-additionally {
    font-size: 0.95rem;
  }
}

/* Keyframe Animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Button Transitions */
button:hover {
  background-color: #ff66b2; /* Pink */
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

button:active {
  transform: scale(0.98);
}

/* Hover Effect for Text */
.policy-text.pri-policy-additionally:hover {
  color: #800000; /* Dark red text on hover */
  transition: color 0.3s ease;
}


/* refund and canc  */
/* Refund and Cancellation Policy Section Styling */
.refund-cancellation-section {
  padding: 50px;
  background-color: #f9f9f9;
  font-family: Arial, sans-serif;
}

.refund-header {
  text-align: center;
  margin-bottom: 40px;
}

.refund-header h1 {
  color: #800000;
  font-size: 2rem;
  font-weight: bold;
  transition: color 0.3s ease;
}

.refund-header p {
  color: #ff66b2;
  font-size: 1rem;
  margin-top: 10px;
}

.refund-content h2 {
  color: #800000;
  font-size: 1.5rem;
  margin-top: 30px;
}

.refund-content p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #333;
}

.refund-content p strong {
  color: #800000;
}

.refund-content a {
  color: #ff66b2;
  text-decoration: none;
  transition: color 0.3s ease;
}

.refund-content a:hover {
  color: #800000;
}

@media (max-width: 768px) {
  .refund-header h1 {
    font-size: 1.5rem;
  }

  .refund-content h2 {
    font-size: 1.3rem;
  }

  .refund-content p {
    font-size: 1rem;
  }
}


/* home page product section css  */
/* Popular Products Section */
.popular-products {
  padding: 40px 8rem;
  background: #f8f8f8;
  text-align: center;
}

/* Section Title */
.section-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #800000;
  margin-bottom: 30px;
  text-transform: uppercase;
}

/* Grid Layout */
.products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
  justify-content: center;
}

/* 3D Flip Card Container */
.product-card {
  width: 100%;
  height: 480px; /* Increased height */
  perspective: 1000px;
  position: relative;
}

/* Inner Flip Effect */
.product-card-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

/* Flip on Click */
.flipped .product-card-inner {
  transform: rotateY(180deg);
}

/* Front & Back Sides */
.product-card-front,
.product-card-back {
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #800000;
}

/* Front Side */
.product-card-front {
  background: white;
}

/* Product Image */
.product-image {
  width: 100%;
  max-height: 220px; /* Increased for better proportion */
  object-fit: cover;
  border-radius: 10px;
}

/* Product Name */
.product-name {
  font-size: 1.8rem;
  font-weight: bold;
  margin: 10px 0;
  color: #333;
  text-transform: capitalize;
}

/* Price Styling */
.product-price {
  font-size: 1.4rem;
  font-weight: bold;
  color: #ff5733;
  background: #ffe6e6;
  padding: 5px 15px;
  border-radius: 5px;
  display: inline-block;
  margin-bottom: 10px;
}

/* Short Description */
.product-description {
  font-size: 1rem;
  color: #555;
  margin-bottom: 15px;
  text-align: center;
  padding: 0 10px;
}

/* Back Side (Product Details) */
.product-card-back {
  background: #800000;
  color: white;
  transform: rotateY(180deg);
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

/* Product Details */
.product-details {
  font-size: 1rem;
  line-height: 1.6;
  text-align: left;
  width: 100%;
  max-width: 90%;
  margin: auto;
  padding: 10px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}

/* Buttons */
.buy-button,
.close-button {
  background: #fff;
  color: #800000;
  border: none;
  padding: 12px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  margin-top: auto;
  font-size: 1rem;
  font-weight: bold;
}

.buy-button:hover,
.close-button:hover {
  background: #a8323e;
  color: white;
}

/* Badge (Explore) */
.badge-for-product {
  position: absolute;
  top: 10px;
  left: 10px;
  background: #ff5733;
  color: white;
  padding: 6px 12px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s;
  font-size: 0.9rem;
}

.badge-for-product:hover {
  background: #c44125;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .popular-products {
    padding: 30px 4rem;
  }

  .product-card {
    height: 450px; /* Adjusted for medium screens */
  }

  .product-image {
    max-height: 200px;
  }
}

@media (max-width: 768px) {
  .popular-products {
    padding: 20px;
  }

  .product-card {
    height: 420px;
  }

  .badge-for-product {
    padding: 5px 10px;
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .products-grid {
    grid-template-columns: 1fr; /* Single column layout */
  }

  .product-card {
    height: 400px;
  }
}
